/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'universal-access': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.5 1.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0M6 5.5l-4.535-.442A.531.531 0 011.531 4H14.47a.531.531 0 01.066 1.058L10 5.5V9l.452 6.42a.535.535 0 01-1.053.174L8.243 9.97c-.064-.252-.422-.252-.486 0l-1.156 5.624a.535.535 0 01-1.053-.174L6 9z"/>',
    },
});
